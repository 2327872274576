import React from "react"
import { css } from "@emotion/core"

import Template from "../components/Template"
import Markdown from "../components/Markdown"

export default () => {
  const techniques = require("../cms/techniques")

  const makeId = text => text.toLowerCase().trim().replace(/ /g, "-")

  const scrollToItem = id => {
    const element = document.querySelector("#" + id)
    element.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <Template title="Techniques">

      {/* wrapping grid */}
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 3fr;
          grid-column-gap: 32px;
          position: relative;

          @media (max-width: 768px) {
            /* since sidebar is hidden at this width */
            grid-template-columns: 1fr;
          }
        `}
      >
        <div
          css={css`
            margin-top: 24px;
            position: sticky;
            top: 32px;
            height: max-content;

            @media (max-width: 768px) {
              /* hide sidebar when screen is too small */
              display: none;
            }
          `}
        >
          <h1
            css={css`
              font-size: 1.2rem;
              margin-bottom: 24px;
            `}
          >
            Techiques
          </h1>

          {techniques.technique_list.map(technique => (
            <button
              key={technique.name}
              css={css`
                color: var(--text-300);
                display: block;
                line-height: 1.4;
                margin-bottom: 16px;
                cursor: pointer;
                text-decoration: underline;
                -webkit-appearance: none;
                background: none;
                border: none;
                text-align: left;

                :focus {
                  outline: 2px dashed var(--accent-100);
                }
              `}
              onClick={() => scrollToItem(makeId(technique.name))}
            >
              {technique.name}
            </button>
          ))}
        </div>

        <div>
          {techniques.technique_list.map(technique => (
            <div
              key={technique.name}
              css={css`
                margin-bottom: 64px;
              `}
            >
              <h1
                css={css`
                  font-size: 1.5rem;
                  line-height: 1.3;
                `}
                id={makeId(technique.name)}
              >
                {technique.name}
              </h1>

              <Markdown raw={technique.description} />
            </div>
          ))}
        </div>
      </div>
    </Template>
  )
}